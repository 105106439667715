import { Icon } from '@src/base/components/icon/icon';

export const CloseIcon = props => {
  return (
    <Icon {...props} name="close">
      <line data-pstroke x1="12.9" y1="12.9" x2="27.1" y2="27.1"/>
      <line data-pstroke x1="27.1" y1="12.9" x2="12.9" y2="27.1"/>
    </Icon>
  );
};

import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from '@src/base/components/layout/layout';
import routes from '@src/routes';

const ContactView = lazy(() => import(/* webpackChunkName: 'projects' */ '@src/contact/views/contact-view'));
const HomeView = lazy(() => import(/* webpackChunkName: 'home' */ '@src/home/views/home-view'));
const ProjectsView = lazy(() => import(/* webpackChunkName: 'projects' */ '@src/projects/views/projects-view'));
const ReadsView = lazy(() => import(/* webpackChunkName: 'reads' */ '@src/reads/views/reads-view'));
const SkillsView = lazy(() => import(/* webpackChunkName: 'skills' */ '@src/skills/views/skills-view'));

export const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map(({ path, name }) => {
            const ViewComponent = getViewComponentByViewName(name);
            const element = (
              <Suspense fallback={<></>}>
                <ViewComponent />
              </Suspense>
            );
            return <Route path={path} element={element} key={name} />;
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

function getViewComponentByViewName(viewName){
  return {
    'contact': ContactView,
    'home': HomeView,
    'projects': ProjectsView,
    'reads': ReadsView,
    'skills': SkillsView
  }[viewName];
}

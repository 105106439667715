import { useTranslation } from '@src/base/hooks/use-translation';
import { useUrl } from '@src/base/hooks/use-url';
import { Button } from '@src/base/components/button/button';
import { Link } from '@src/base/components/link/link';
import translations from './menu.trans';

export const Menu = ({ onMenuItemClick, menuItemsTabIndex, ...rest }) => {
  const { t } = useTranslation(translations);
  const { blogUrl } = useUrl();
  const items = [
    { to: '/skills', text: t('skills'), as: Link, onClick: onMenuItemClick },
    { to: '/projects', text: t('projects'), as: Link, onClick: onMenuItemClick },
    { href: blogUrl, text: t('blog'), as: 'a' },
    { to: '/contact', text: t('contact'), as: Link, onClick: onMenuItemClick }
  ];

  return (
    <ul className="p-menu" role="menu" aria-label={t('website_menu')} {...rest}>
      {items.map(({ to, href, text, as, onClick }, index) => (
        <li key={index}>
          <Button
            as={as}
            to={to}
            href={href}
            tabIndex={isActive(to) ? -1 : menuItemsTabIndex}
            className={isActive(to) ? 'p-menu-item-active' : ''}
            aria-current={buildAriaCurrent(to)}
            onClick={onClick}
          >
            {text}
          </Button>
        </li>
      ))}
    </ul>
  );
};

function isActive(itemRoutePath){
  return window.location.pathname.includes(itemRoutePath);
}

function buildAriaCurrent(itemRoutePath){
  if(isActive(itemRoutePath)) return 'page';
}

import { useTranslation } from '@src/base/hooks/use-translation';
import translations from './use-url.trans.json';

export const useUrl = () => {
  const { t } = useTranslation(translations);

  return {
    blogUrl: t('blog'),
    veedgeeUrl: t('veedgee')
  };
};

import '@src/vars.css';
import '@src/fonts.css';
import '@src/index.css';
import { createRoot } from 'react-dom/client';
import { App } from './app';

export const init = () => {
  const container = document.querySelector('[data-app]');
  if(container) createRoot(container).render(<App />);
};

init();

const contactTranslations = require('./contact/views/contact-view.trans.json');
const homepageTranslations = require('./home/views/home-view.trans.json');
const projectsTranslations = require('./projects/views/projects-view.trans.json');
const readsTranslations = require('./reads/views/reads-view.trans.json');
const skillsTranslations = require('./skills/views/skills-view.trans.json');

module.exports = [
  {
    path: '/',
    name: 'home',
    title: 'Rafael Camargo',
    description: homepageTranslations['en-US'].meta_description,
    keywords: homepageTranslations['en-US'].meta_keywords,
    prerender: true,
  },
  {
    path: '/skills',
    name: 'skills',
    title: skillsTranslations['en-US'].meta_title,
    description: skillsTranslations['en-US'].skills,
    keywords: skillsTranslations['en-US'].meta_keywords,
    prerender: true
  },
  {
    path: '/projects',
    name: 'projects',
    title: projectsTranslations['en-US'].meta_title,
    description: projectsTranslations['en-US'].meta_description,
    keywords: projectsTranslations['en-US'].meta_keywords,
    prerender: true
  },
  {
    path: '/contact',
    name: 'contact',
    title: contactTranslations['en-US'].meta_title,
    description: contactTranslations['en-US'].meta_description,
    keywords: contactTranslations['en-US'].meta_keywords,
    prerender: true
  },
  {
    path: '/reads',
    name: 'reads',
    title: readsTranslations['en-US'].meta_title,
    description: readsTranslations['en-US'].meta_description,
    keywords: readsTranslations['en-US'].meta_keywords,
    prerender: true
  }
];

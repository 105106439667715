import { useContext } from 'react';
import { useTranslation } from '@src/base/hooks/use-translation';
import { LocaleIcon } from '@src/base/icons/locale';
import i18nContext from '@src/base/contexts/i18n';
import translations from './locale-select.trans';

export const LocaleSelect = ({ ...rest }) => {
  const { currentLocale, handleLocaleChange, locales } = useContext(i18nContext);
  const { t } = useTranslation(translations);
  const handleChange = ({ target: { value } }) => handleLocaleChange(value);

  return (
    <div className="p-locale-select-wrapper">
      <select
        name="locale" aria-label={t('language')}
        value={currentLocale}
        onChange={handleChange}
        {...rest}
      >
        {locales?.map(({ code, name }) => (
          <option key={code} value={code}>{name}</option>
        ))}
      </select>
      <LocaleIcon locale={currentLocale}/>
    </div>
  );
};

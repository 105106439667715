import { Topbar } from '@src/base/components/topbar/topbar';

export const Layout = ({ children }) => {
  return (
    <div className="p-layout">
      <Topbar />
      <main>
        {children}
      </main>
    </div>
  );
};

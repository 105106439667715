import { Icon } from '@src/base/components/icon/icon';

export const MenuIcon = props => {
  return (
    <Icon {...props} name="menu">
      <line data-pstroke x1="10" y1="16.5" x2="30" y2="16.5"/>
      <line data-pstroke x1="10" y1="23.5" x2="30" y2="23.5"/>
    </Icon>
  );
};

import { Icon } from '@src/base/components/icon/icon';

export const ThemeIcon = props => {
  return (
    <Icon {...props} name="theme">
      <line data-pstroke x1="20.4" y1="10.4" x2="20.4" y2="30.4"/>
      <line data-pstroke x1="10.6" y1="18.4" x2="20.4" y2="18.4"/>
      <line data-pstroke x1="12.4" y1="14.4" x2="20.4" y2="14.4"/>
      <line data-pstroke x1="10.6" y1="22.4" x2="20.4" y2="22.4"/>
      <line data-pstroke x1="12.4" y1="26.4" x2="20.4" y2="26.4"/>
      <circle data-pstroke cx="20.4" cy="20.4" r="10"/>
    </Icon>
  );
};

import { useState } from 'react';
import { useTranslation } from '@src/base/hooks/use-translation';
import { Button } from '@src/base/components/button/button';
import { Link } from '@src/base/components/link/link';
import { LocaleSelect } from '@src/base/components/locale-select/locale-select';
import { CloseIcon } from '@src/base/icons/close';
import { LogoIcon } from '@src/base/icons/logo';
import { MenuIcon } from '@src/base/icons/menu';
import { Menu } from '@src/base/components/menu/menu';
import { toggleTheme } from '@src/base/services/theme';
import { ThemeIcon } from '@src/base/icons/theme';
import translations from './topbar.trans';

const TOPBAR_MENU_ID = 'topbarMenu';

export const Topbar = () => {
  const { t } = useTranslation(translations);
  const [topbarState, setTopbarState] = useState({});
  const toggleMenuVisibility = () => {
    setTopbarState(prevState => ({
      ...prevState,
      isMenuVisible: !prevState.isMenuVisible,
      cssClassName: buildTopbarClassName(!prevState.isMenuVisible)
    }));
  };

  return (
    <div className={buildTopbarClassName(topbarState.isMenuVisible)} id="topbar">
      <div className="p-topbar-content">
        <div className="p-topbar-menu-wrapper" aria-hidden={!topbarState.isMenuVisible}>
          <Menu
            id={TOPBAR_MENU_ID}
            menuItemsTabIndex={getMenuItemTabIndex(topbarState.isMenuVisible)}
            onMenuItemClick={toggleMenuVisibility}
          />
          <Button
            aria-label={t('hide_menu')}
            theme="icon"
            tabIndex={getMenuItemTabIndex(topbarState.isMenuVisible)}
            onClick={toggleMenuVisibility}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="p-topbar-main">
          <Button
            as={Link}
            to="/"
            aria-label={t('homepage')}
            className="p-topbar-homepage-link"
            tabIndex={getDefaultItemTabIndex(topbarState.isMenuVisible)}
          >
            <LogoIcon /> Rafael Camargo
          </Button>
          <ul className="p-topbar-actions">
            <li>
              <Button
                aria-label={t('toggle_theme')}
                theme="icon"
                tabIndex={getDefaultItemTabIndex(topbarState.isMenuVisible)}
                onClick={toggleTheme}
              >
                <ThemeIcon />
              </Button>
            </li>
            <li>
              <LocaleSelect tabIndex={getDefaultItemTabIndex(topbarState.isMenuVisible)} />
            </li>
            <li>
              <Button
                aria-label={t('show_menu')}
                aria-hidden={topbarState.isMenuVisible}
                theme="icon"
                tabIndex={getDefaultItemTabIndex(topbarState.isMenuVisible)}
                onClick={toggleMenuVisibility}
              >
                <MenuIcon />
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function getDefaultItemTabIndex(isMenuVisible){
  return isMenuVisible ? -1 : 0;
}

function getMenuItemTabIndex(isMenuVisible){
  return isMenuVisible ? 0 : -1;
}

function buildTopbarClassName(isMenuVisible){
  const baseClassName = 'p-topbar';
  return isMenuVisible ? `${baseClassName} p-topbar-menu-open` : baseClassName;
}

import { Icon } from '@src/base/components/icon/icon';

export const LogoIcon = props => {
  return (
    <Icon {...props} name="logo">
      <path data-pshape d="M18.1,3.5C11.9,3.5,6.6,7,3.9,12h2.7c2.4-3.7,6.6-6.2,11.4-6.2c7.5,0,13.7,6.1,13.7,13.7c0,7.5-6.1,13.7-13.7,13.7c-4.8,0-9-2.5-11.4-6.2H3.9c2.7,5.1,8,8.5,14.1,8.5c8.8,0,16-7.2,16-16C34.1,10.7,26.9,3.5,18.1,3.5z"/>
      <path data-pshape d="M18.3,27h-0.4h-2.1h-0.1l-0.1-0.1l-3.2-5.1H8.9v5V27H8.7H6.9H6.7v-0.2V12.2V12h0.2h6.2c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.1c0.4,0.5,0.7,1,0.9,1.6c0.2,0.6,0.3,1.2,0.3,1.8c0,0.5-0.1,1-0.2,1.5c-0.1,0.5-0.4,0.9-0.6,1.3    c-0.3,0.4-0.6,0.7-1,1c-0.3,0.2-0.7,0.4-1.1,0.5l3.4,5.3L18.3,27L18.3,27z M8.9,19.7h4.2c0.3,0,0.7-0.1,0.9-0.2    c0.3-0.1,0.5-0.4,0.7-0.6c0.2-0.3,0.4-0.6,0.5-0.9c0.1-0.3,0.2-0.7,0.2-1.1c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.5-0.5-0.8-0.6c-0.3-0.1-0.6-0.2-0.9-0.2H8.9L8.9,19.7L8.9,19.7L8.9,19.7z"/>
    </Icon>
  );
};

import { Icon } from '@src/base/components/icon/icon';

export const LocaleIcon = ({ locale, ...rest }) => {
  return (
    <Icon {...rest} name="locale" id={`locale_${locale.replace('-', '')}`}>
      {getLocaleCode(locale)}
      <line data-pstroke x1="20" y1="10" x2="20" y2="30"/>
      <path data-pstroke d="M20,10c5.5,0,10,4.5,10,10s-4.5,10-10,10s-10-4.5-10-10"/>
      <path data-pstroke d="M20,10c2.8,0,5,4.5,5,10s-2.2,10-5,10s-5-4.5-5-10"/>
      <line data-pstroke x1="10" y1="20" x2="30" y2="20"/>
      <line data-pstroke x1="20" y1="16" x2="29.2" y2="16"/>
      <line data-pstroke x1="10.8" y1="24" x2="29.2" y2="24"/>
    </Icon>
  );
};

function getLocaleCode(locale){
  return {
    'en-US': (
      <>
        <path data-pshape d="M6.4,11h3.8v0.8H7.3v1.8H10v0.8H7.3v1.8h2.9V17H6.4V11z"/>
        <path data-pshape d="M11.4,11h1.1l2.3,3.6c0.2,0.3,0.5,0.8,0.7,1.3c0-0.6-0.1-1.1-0.1-1.5V11h0.9v6h-1.1l-2-3.2c-0.3-0.5-0.5-0.9-1-1.7c0,0.8,0.1,1.4,0.1,1.7V17h-0.9V11z"/>
      </>
    ),
    'pt-BR': (
      <>
        <path data-pshape d="M6.4,11h2.2c1.4,0,2.1,0.8,2.1,1.9s-0.7,1.9-2.1,1.9H7.3V17H6.4V11z M8.5,14.1c0.9,0,1.2-0.5,1.2-1.2c0-0.7-0.4-1.2-1.3-1.2H7.3v2.3H8.5z"/>
        <path data-pshape d="M11.4,11h4.7v0.8h-1.9V17h-0.9v-5.2h-1.9V11z"/>
      </>
    )
  }[locale];
}

export const Button = ({ children, theme, className, block, as, ...rest }) => {
  const Element = as ? as : 'button';
  return (
    <Element className={buildCSSClasses(theme, block, className)} {...rest}>
      {children}
    </Element>
  );
};

function buildCSSClasses(theme, block, className){
  const baseCssClass = 'p-button';
  const themeCssClass = theme && `p-button-${theme}`;
  const blockCssClass = block && 'p-button-block';
  return [
    baseCssClass, 
    blockCssClass, 
    themeCssClass, 
    className
  ].filter(value => !!value).join(' ');
}

import {
  THEME_STORAGE_KEY,
  THEME_GLOBAL_ATTRIBUTE,
  THEME_DARK_ID,
  THEME_LIGHT_ID
} from '@src/base/constants/theme';

export const setInitialTheme = () => setTheme(getTheme());

export const getTheme = () => {
  const currentTheme = document.body.getAttribute(THEME_GLOBAL_ATTRIBUTE);
  const storedTheme = window.localStorage.getItem(THEME_STORAGE_KEY);
  return storedTheme || currentTheme || THEME_LIGHT_ID;
};

export const setTheme = theme => {
  document.body.setAttribute(THEME_GLOBAL_ATTRIBUTE, theme);
  window.localStorage.setItem(THEME_STORAGE_KEY, theme);
};

export const toggleTheme = () => {
  const currentTheme = getTheme();
  const newTheme = currentTheme === THEME_LIGHT_ID ? THEME_DARK_ID : THEME_LIGHT_ID;
  setTheme(newTheme);
};
